import { Alert, Button, Form, Input, notification, Space, Spin } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Environment } from '../../app/environment';
import logger from '../../app/logger';
import { isRequestNonceResponseSuccess } from '../../shared/authorize-net/utils/utils';
import { LogEntry } from '../../shared/models/logentry';
import { PaymentFormProps } from './payment-form.props';
import { PaymentsAPI } from './payments.api';
import { Merchant, PaymentType } from './payments.model';
import { PaymentAmountInput } from './PaymentAmountInput';
import { moneyToNumber } from '../../shared/antd/components/MoneyInput';
import { MaskedInput, MaskedInputRef } from '../../shared/antd/components/MaskedInput';
import IMask from 'imask';
import { MakePaymentArgs } from './thunk/makePayment';
import { useAppDispatch } from '../../app/hooks';
import { setOrderProcessing } from './Checkout.slice';

export type CardPaymentProps = PaymentFormProps & {
    siteId: string;
};

type CardPaymentFormState = {
    number: string;
    exp_date: string;
    code: string;
    name_on_card: string;
    amount: string;
};
export const CardPaymentForm = ({
    onFinish,
    onError,
    onCancel,
    amountDue,
    siteId,
    processing,
}: CardPaymentProps): JSX.Element => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<CardPaymentFormState>();
    const [scriptLoading, setScriptLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<AuthorizeNet.ResponseMessage[]>([]);
    const [gatewayProcessing, setGatewayProcessing] = useState<boolean>(false);
    const [merchant, setMerchant] = useState<Merchant | null>();
    const merchantLoading = useRef<boolean>(false);
    const ccInputRef = useRef<MaskedInputRef>(null);
    const expInputRef = useRef<MaskedInputRef>(null);
    const cvvInputRef = useRef<MaskedInputRef>(null);

    const handlePaymentGatewayResponse = useCallback(
        (amount: number) =>
            async (response: AuthorizeNet.RequestNonceResponse): Promise<void> => {
                dispatch(setOrderProcessing(true));
                setGatewayProcessing(false);

                if (isRequestNonceResponseSuccess(response)) {
                    const payload = {
                        opaqueData: response.opaqueData,
                        amount,
                        paymentType: PaymentType.Card,
                        siteId,
                        receipts: [],
                    } as unknown as MakePaymentArgs;
                    onFinish(payload);
                } else {
                    dispatch(setOrderProcessing(false));
                    logger.error(new LogEntry('payment gateway response', response));
                    setErrors(response.messages.message);
                    onError(new Error('Failed to make payment. Card authorization failed'));
                }
            },
        [dispatch, onError, onFinish, siteId],
    );

    const submitPayment = useCallback(
        (values: CardPaymentFormState): void => {
            if (merchant) {
                logger.debug(new LogEntry('submitPayment', values));

                const authData: AuthorizeNet.AuthData = {
                    apiLoginID: merchant?.apiLoginId,
                    clientKey: merchant?.publicClientKey,
                };

                const parts = expInputRef.current?.getUnMaskedValue()?.split('/');
                const cardNumber = ccInputRef.current?.getUnMaskedValue();
                const cardCode = cvvInputRef.current?.getUnMaskedValue();
                const month = parts?.[0].trim();
                const year = parts?.[1].trim();

                if (cardNumber && cardCode && month && year) {
                    const cardData: AuthorizeNet.CardData = {
                        cardNumber,
                        month,
                        year,
                        cardCode,
                        fullName: values.name_on_card,
                    };

                    try {
                        setErrors([]);
                        setGatewayProcessing(true);
                        window.Accept.dispatchData(
                            {
                                authData,
                                cardData,
                            },
                            handlePaymentGatewayResponse(moneyToNumber(values.amount)),
                        );
                    } catch (err) {
                        onError(err);
                        notification.error({
                            message: 'Failed to initiate payment',
                            duration: 5,
                            placement: 'bottomLeft',
                        });
                        setGatewayProcessing(false);
                    }
                } else {
                    onError('Card info is not correct');
                }
            }
        },
        [handlePaymentGatewayResponse, merchant, onError],
    );

    useEffect(() => {
        if (!merchantLoading.current && merchant === undefined && siteId) {
            merchantLoading.current = true;

            PaymentsAPI.getMerchantForSiteId(siteId)
                .then(setMerchant)
                .catch(() => setMerchant(null))
                .finally(() => (merchantLoading.current = false));
        }
    }, [merchant, siteId]);

    useEffect(() => {
        if (merchant) {
            const scriptId = 'authorizenet-script';
            if (!document.getElementById(scriptId)) {
                setScriptLoading(true);
                const script = document.createElement('script');
                script.id = scriptId;
                script.src = merchant.isSandboxAccount
                    ? Environment.authorizeNetAcceptJSSandboxUrl
                    : Environment.authorizeNetAcceptJSProdUrl;
                script.defer = true;
                script.onload = () => setScriptLoading(false);

                document.body.appendChild(script);

                return () => {
                    if (script) script.remove();
                };
            }
        }
    }, [merchant]);

    return (
        <Spin spinning={merchantLoading.current || scriptLoading}>
            <Form
                form={form}
                layout='vertical'
                requiredMark={true}
                onFinish={submitPayment}
                colon={false}
                labelWrap={false}
                validateTrigger={['onChange', 'onBlur']}>
                <Form.Item
                    label='Credit Card'
                    name='number'
                    rules={[
                        {
                            required: true,
                            message: 'Credit card number is required',
                        },
                        {
                            validator: () => {
                                const value = ccInputRef.current?.getUnMaskedValue();
                                return value && ccInputRef.current?.isComplete() ? Promise.resolve() : Promise.reject();
                            },
                            message: 'Credit card number is incorrect',
                            validateTrigger: 'onBlur',
                        },
                    ]}
                    validateFirst={true}>
                    <MaskedInput
                        maskOptions={{ mask: '0000 0000 0000 000[0]', placeholderChar: '0' }}
                        autoComplete='cc-number'
                        ref={ccInputRef}
                        inputMode='numeric'
                    />
                </Form.Item>

                <Space direction='horizontal' align='start' style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Form.Item
                        className='exp-date'
                        label={<span style={{ whiteSpace: 'nowrap', overflow: 'visible' }}>Expiration Date</span>}
                        name={'exp_date'}
                        rules={[
                            {
                                required: true,
                                message: 'Exp. date is required',
                            },
                            {
                                validator() {
                                    const value = expInputRef.current?.getUnMaskedValue();

                                    if (value) {
                                        const parts = value.split('/');
                                        const monthStr = parts[0].trim();
                                        const yearStr = parts[1].trim();

                                        if (!monthStr) return Promise.reject('Month is required');

                                        if (!Number.isFinite(Number.parseInt(monthStr)))
                                            return Promise.reject('Month must be a number between 1 and 12');

                                        const month = Number(monthStr);
                                        if (month < 1 || month > 12)
                                            return Promise.reject('Month must be between 1 and 12');

                                        if (!yearStr) return Promise.reject('Year is required');
                                        if (!Number.isFinite(Number.parseInt(yearStr)))
                                            return Promise.reject('Month must be a valid number');

                                        return Promise.resolve();
                                    } else {
                                        return Promise.reject();
                                    }
                                },
                            },
                        ]}
                        validateFirst>
                        <MaskedInput
                            maskOptions={{
                                mask: 'm {/} y',
                                lazy: false,
                                overwrite: false,
                                autofix: false,
                                //placeholderChar: ' ',
                                blocks: {
                                    m: {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to: 12,
                                        maxLength: 2,
                                        autofix: 'pad',
                                    },
                                    y: {
                                        mask: IMask.MaskedRange,
                                        from: 0,
                                        to: 99,
                                        maxLength: 2,
                                    },
                                },
                            }}
                            autoComplete='cc-exp'
                            ref={expInputRef}
                            inputMode='numeric'
                        />
                    </Form.Item>

                    <Form.Item
                        style={{ width: 85 }}
                        label='CVV'
                        name='code'
                        rules={[
                            {
                                required: true,
                                message: 'CVV is required',
                            },
                            {
                                validator: () => {
                                    const value = cvvInputRef.current?.getUnMaskedValue();
                                    return value && cvvInputRef.current?.isComplete()
                                        ? Promise.resolve()
                                        : Promise.reject();
                                },
                                message: 'CVV is incorrect',
                                validateTrigger: 'onBlur',
                            },
                        ]}
                        validateFirst={true}
                        validateTrigger={['onChange', 'onBlur']}>
                        <MaskedInput
                            maskOptions={{ mask: '000[0]' }}
                            placeholder='&#8226;&#8226;&#8226;&#8226;'
                            autoComplete='cc-csc'
                            type='password'
                            ref={cvvInputRef}
                            inputMode='numeric'
                            inputType='password'
                        />
                    </Form.Item>
                </Space>

                <Form.Item
                    label='Name'
                    name='name_on_card'
                    rules={[
                        {
                            required: true,
                            message: 'Card holder name is required',
                        },
                    ]}>
                    <Input placeholder='Full Name on Card' autoComplete='cc-name' />
                </Form.Item>

                <PaymentAmountInput defaultValue={amountDue} />

                <Button
                    type='primary'
                    htmlType='submit'
                    loading={processing || gatewayProcessing}
                    disabled={processing || !merchant || gatewayProcessing}>
                    Confirm Order
                </Button>

                {errors.length > 0 && errors.map((err) => <Alert message={err.text} type='error' key={err.code} />)}
            </Form>
            {!merchant && <Alert message='Failed to aquire merchant info' type='error' />}
        </Spin>
    );
};
