import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormInstance, InputRef, notification, Popover, Spin, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ConfiguratorAPI } from '../../shared/services/configurator.service';
import { LogikFields, LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { LogikInput, LogikInputProps } from '../../shared/logik/components/LogikInput';
import { CustomerFormState } from './CustomerPage';
import { useAppSelector } from '../../app/hooks';
import { HSContact } from '../../shared/models/hs-contact.model';
import { debounce } from 'lodash';
import { useScreenChange } from '../../shared/hooks/screen';

export enum ContactLookupFieldEnum {
    name = 'name',
    email = 'email',
}
export type CustomerLookupInputProps = LogikInputProps & {
    field: ContactLookupFieldEnum;
    customerForm: FormInstance<CustomerFormState>;
    canReopenPopover: boolean;
    updateCanReopenPopover: (field: ContactLookupFieldEnum, value: boolean) => void;
    updateShippingFields: (fields: Partial<CustomerFormState>) => void;
    validateShippingFields: () => void;
};
export const CustomerLookupInput = React.forwardRef<InputRef, CustomerLookupInputProps>(function CustomerLookupInput(
    {
        field,
        customerForm,
        canReopenPopover,
        updateCanReopenPopover,
        updateShippingFields,
        validateShippingFields,
        ...props
    }: CustomerLookupInputProps & React.RefAttributes<InputRef>,
    ref,
): JSX.Element {
    const uuid = useAppSelector((state) => state.widget.uuid);
    const { device } = useScreenChange();
    const isDesktopDevice = device === 'desktop';

    const cusName = LogikFieldsEnum.cusName;
    const cusEmail = LogikFieldsEnum.cusEmail;
    const cusCompany = LogikFieldsEnum.cusCompany;

    const shippingAddress = LogikFields.getLogikFieldNameAndRequire(`shipping_address`);
    const shippingCity = LogikFields.getLogikFieldNameAndRequire(`shipping_city`);
    const shippingState = LogikFields.getLogikFieldNameAndRequire(`shipping_state`);
    const shippingZip = LogikFields.getLogikFieldNameAndRequire(`shipping_zip`);
    const shippingPhone = LogikFields.getLogikFieldNameAndRequire(`shipping_primary_phone`);

    const [contacts, setContacts] = useState<HSContact[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDebouncing, setIsDebouncing] = useState<boolean>();
    const [isCancelSearch, setIsCancelSearch] = useState<boolean>();
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);

    const oppositeField =
        field === ContactLookupFieldEnum.name ? ContactLookupFieldEnum.email : ContactLookupFieldEnum.name;
    const inputName = field === ContactLookupFieldEnum.name ? cusName : cusEmail;
    const debounceTime = 500;

    const columns = useMemo(() => {
        return isDesktopDevice
            ? [
                  { title: 'Name', dataIndex: 'fullName', key: 'fullName', width: 130 },
                  { title: 'Email', dataIndex: 'email', key: 'email', width: 150 },
                  { title: 'Phone', dataIndex: 'phone', key: 'phone', width: 120 },
                  { title: 'Address', dataIndex: 'fullAddress', key: 'fullAddress', width: 190 },
              ]
            : [
                  {
                      title: 'Customer',
                      dataIndex: 'details',
                      key: 'details',
                      render: (text: string) => <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>,
                  },
              ];
    }, [isDesktopDevice]);

    const getFullAddress = useCallback(
        (item: HSContact) =>
            [
                item.address,
                item.city,
                item.state && item.zipCode ? `${item.state} ${item.zipCode}` : item.state || item.zipCode,
            ]
                .filter(Boolean)
                .join(', '),
        [],
    );

    const getDetails = useCallback(
        (item: HSContact) => [item.fullName, item.email, item.phone, getFullAddress(item)].filter(Boolean).join('\n'),
        [getFullAddress],
    );

    const tableContent = useMemo(
        () =>
            contacts.map((item) =>
                isDesktopDevice
                    ? { ...item, key: item.id, fullAddress: getFullAddress(item) }
                    : { key: item.id, details: getDetails(item), original: item },
            ),
        [isDesktopDevice, contacts, getFullAddress, getDetails],
    );

    useEffect(() => {
        if (isDebouncing === false) {
            if (isCancelSearch) {
                setPopoverVisible(false);
                updateCanReopenPopover(field, false);
                setContacts([]);
            } else {
                setPopoverVisible(!!contacts && contacts.length > 0);
                updateCanReopenPopover(field, !!contacts && contacts.length > 0);
            }
        }
    }, [isCancelSearch, isDebouncing, contacts, field, updateCanReopenPopover, setPopoverVisible, setContacts]);

    const fetchData = useCallback(
        async (value: string) => {
            if (!value) {
                setPopoverVisible(false);
                setContacts([]);
                return;
            }

            setIsLoading(true);
            setIsDebouncing(true);

            try {
                const contacts = await ConfiguratorAPI.getHSContacts(
                    uuid ?? '',
                    `${field}=${encodeURIComponent(value)}`,
                );
                setContacts(contacts);
            } catch (error) {
                notification.error({
                    message: 'Error getting HS Contacts',
                    duration: 3,
                    placement: 'top',
                });
                setContacts([]);
            } finally {
                setIsLoading(false);
                setIsDebouncing(false);
            }
        },
        [uuid, field, setPopoverVisible, setContacts, setIsLoading, setIsDebouncing],
    );

    const debouncedLookup = useMemo(() => debounce(fetchData, debounceTime), [fetchData, debounceTime]);

    const onChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setIsCancelSearch(!value && isDebouncing);
            setPopoverVisible(false);
            customerForm.setFieldsValue({ [inputName]: value });
            customerForm.validateFields([inputName]);
            debouncedLookup(value);
        },
        [customerForm, debouncedLookup, inputName, isDebouncing, setIsCancelSearch, setPopoverVisible],
    );

    const onKeyDown = useCallback(
        (event: React.KeyboardEvent<HTMLInputElement>) => {
            const { key } = event;

            if (key === 'Escape') {
                setPopoverVisible(false);
            }
        },
        [setPopoverVisible],
    );

    const onClick = useCallback(() => {
        if (canReopenPopover && !isDebouncing && customerForm.getFieldValue(inputName).length > 0) {
            setPopoverVisible(true);
        }
    }, [canReopenPopover, customerForm, inputName, isDebouncing, setPopoverVisible]);

    const onBlur = useCallback(() => {
        setIsCancelSearch(isDebouncing);
        setPopoverVisible(false);
        debouncedLookup.cancel();
        customerForm.validateFields([inputName]);
    }, [customerForm, debouncedLookup, inputName, isDebouncing, setIsCancelSearch, setPopoverVisible]);

    const onRow = useCallback(
        (record: HSContact & { original?: HSContact }) => {
            return {
                onMouseDown: () => {
                    const contact = isDesktopDevice ? record : record.original;
                    customerForm.setFieldsValue({
                        [cusName]: contact?.fullName,
                        [cusEmail]: contact?.email,
                        [cusCompany]: contact?.company,
                    });
                    updateShippingFields({
                        [shippingAddress]: contact?.address,
                        [shippingCity]: contact?.city,
                        [shippingState]: contact?.state,
                        [shippingZip]: contact?.zipCode,
                        [shippingPhone]: contact?.phone,
                    });
                    validateShippingFields();
                    setPopoverVisible(false);
                    updateCanReopenPopover(field, false);
                    updateCanReopenPopover(oppositeField, false);
                },
            };
        },
        [
            cusName,
            cusEmail,
            cusCompany,
            shippingAddress,
            shippingCity,
            shippingState,
            shippingZip,
            shippingPhone,
            field,
            customerForm,
            isDesktopDevice,
            oppositeField,
            updateCanReopenPopover,
            updateShippingFields,
            validateShippingFields,
            setPopoverVisible,
        ],
    );

    const tableStyle = useMemo(() => {
        return isDesktopDevice ? { width: 700 } : { width: 250 };
    }, [isDesktopDevice]);

    const popoverContent = (
        <Table
            columns={columns}
            dataSource={tableContent}
            onRow={onRow}
            pagination={false}
            scroll={{ y: 240 }}
            style={tableStyle}
        />
    );

    const loadingSuffix = useMemo(() => {
        return isLoading ? <Spin indicator={<LoadingOutlined spin />} size='small' /> : null;
    }, [isLoading]);

    return (
        <Popover placement='bottom' trigger={'click'} content={popoverContent} open={popoverVisible}>
            <div>
                <LogikInput
                    {...props}
                    suffix={loadingSuffix}
                    autoComplete='off'
                    autoFocus={field === ContactLookupFieldEnum.name}
                    placeholder={`Enter your ${field}`}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onClick={onClick}
                    onBlur={onBlur}
                    ref={ref}
                />
            </div>
        </Popover>
    );
});
