import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
import { RootState } from '../../../../app/store';
import { DiscountTypes, LogikFieldsEnum, SerialTypeEnum } from '../../../../shared/logik/constants/fields.constant';
import { Configuration } from '../../../../shared/logik/models/configuration.model';
import { FieldUpdate, getFieldValueFromArray, SetFieldUpdate } from '../../../../shared/logik/models/field.model';
import logikApiService from '../../../../shared/logik/services/logik-api.service';
import { ConfiguratorAPI } from '../../../../shared/services/configurator.service';
import { getNewConfigurationDefaultInput, getOrderDateUpdate, getUTCInISO } from '../../../wizard/wizard.helpers';
import { setActionType, setThreekitId, setUUID, WidgetState } from '../../Widget.slice';
import { fetchPricingDetails } from '../basic/fetchPricingDetails';
import { fetchSets } from '../basic/fetchSets';
import { postProcessConfiguration } from '../basic/postProcessConfiguration';
import { updateConfiguration } from '../basic/updateConfiguration';
import { getDefaultLayout } from '../../../../shared/logik/utils/layouts.utils';
import { fetchTooltips } from '../basic/fetchTooltips';
import { setUserProfile } from './setUserProfile';
import { ProductsTemplatesAPI } from '../../../products-templates/product-templates.service';
import { setProductsTemplates } from '../../../products/Products.slice';
import { removeDeprecatedDiscounts } from '../actions/removeDeprecatedDiscounts';

export const createInitConfiguration = createAsyncThunk<
    Configuration,
    void,
    {
        state: RootState;
    }
>('init/create', async (_, thunkAPI): Promise<Configuration> => {
    const dispatch = thunkAPI.dispatch;

    const state = thunkAPI.getState();
    const widgetState = state.widget;
    const appSrc = widgetState.init?.src;
    const origSiteId = widgetState.init?.siteId;

    const productId = widgetState.productId;
    const store = widgetState.store;
    const prebuilt = state.prebuilt.details;
    const preowned = state.preowned.details;

    let productTemplate = productId ? state.products.productsDefaults[productId] : undefined;

    if (!widgetState.init?.actionType) dispatch(setActionType('QuoteNew'));

    if (productId && !productTemplate) {
        const productsDefaults = await ProductsTemplatesAPI.list();
        thunkAPI.dispatch(setProductsTemplates(productsDefaults));
        productTemplate = thunkAPI.getState().products.productsDefaults[productId];
    }

    let updates = getNewConfigurationDefaultInput(productId, widgetState.init?.initialConfiguration, store, prebuilt);

    if (widgetState.init?.allowVariants !== undefined) {
        updates = [
            {
                variableName: LogikFieldsEnum.allowVariants,
                value: widgetState.init.allowVariants,
            },
            ...updates,
        ];
    }
    const defaultDiscountUpdates = [] as SetFieldUpdate[];
    const discountsSetName = LogikFieldsEnum.set_of_discounts;

    let configuration: Configuration;
    const inventoryUpdates: FieldUpdate[] = [
        {
            variableName: LogikFieldsEnum.shipping_zip,
            value: '',
        },
    ];
    if (prebuilt?.masterConfigurationId) {
        const prebuiltConfig = await ConfiguratorAPI.getConfigurationByMasterId(prebuilt.masterConfigurationId);
        updates = [
            ...updates,
            {
                variableName: LogikFieldsEnum.prebuiltIsMasterConfig,
                value: false,
            },
            {
                variableName: LogikFieldsEnum.prebuiltMasterConfigUUID,
                value: prebuiltConfig.logikId,
            },
            {
                variableName: LogikFieldsEnum.warehouse,
                value: prebuilt.warehouse.warehouseId,
            },
            {
                variableName: LogikFieldsEnum.serialType,
                value: SerialTypeEnum.prebuilt,
            },
            ...inventoryUpdates,
        ];
        if (prebuilt.siteId) {
            updates.push(getOrderDateUpdate(getUTCInISO(), prebuilt.siteId));
        }
        configuration = await logikApiService.copyConfiguration(prebuiltConfig.logikId, updates);
        dispatch(setThreekitId(prebuiltConfig.threeKitId));
    } else if (preowned?.masterConfigurationId) {
        const prebuiltConfig = await ConfiguratorAPI.getConfigurationByMasterId(preowned.masterConfigurationId);
        updates = [
            ...updates,
            {
                variableName: LogikFieldsEnum.prebuiltIsMasterConfig,
                value: false,
            },
            {
                variableName: LogikFieldsEnum.prebuiltMasterConfigUUID,
                value: prebuiltConfig.logikId,
            },
            {
                variableName: LogikFieldsEnum.warehouse,
                value: preowned.warehouse.warehouseId,
            },
            {
                variableName: LogikFieldsEnum.serialType,
                value: SerialTypeEnum.preowned,
            },
            ...inventoryUpdates,
        ];

        if (preowned.siteId) {
            updates.push(getOrderDateUpdate(getUTCInISO(), preowned.siteId));
        }

        configuration = await logikApiService.copyConfiguration(prebuiltConfig.logikId, updates);
        dispatch(setThreekitId(prebuiltConfig.threeKitId));
    } else if (productTemplate && productTemplate.status === 'Active') {
        const productTemplateConfig = await ConfiguratorAPI.getConfigurationByMasterId(productTemplate.masterId);
        const productTemplateLogikConfig = await logikApiService.fetchConfiguration(productTemplateConfig.logikId);
        updates = [
            {
                variableName: LogikFieldsEnum.shipping_zip,
                value: '',
            },
            ...updates,
        ];

        const templateSiteId = getFieldValueFromArray<string>(
            productTemplateLogikConfig.fields,
            LogikFieldsEnum.siteId,
        );

        if (templateSiteId) {
            updates.push(getOrderDateUpdate(getUTCInISO(), templateSiteId));
        }

        configuration = await logikApiService.copyConfiguration(productTemplateConfig.logikId, updates);
        dispatch(setThreekitId(productTemplateConfig.threeKitId));
    } else {
        if (origSiteId) {
            updates.push(getOrderDateUpdate(getUTCInISO(), origSiteId));
        }

        configuration = await logikApiService.createConfiguration(updates);

        if (appSrc === 'public') {
            const defaultDiscounts = [DiscountTypes.discPromo];

            let discountIndex = 0;

            if (defaultDiscounts?.includes(DiscountTypes.discPromo)) {
                defaultDiscountUpdates.push({
                    variableName: LogikFieldsEnum.set_of_discounts_discount_type,
                    index: discountIndex,
                    value: DiscountTypes.discPromo,
                    set: discountsSetName,
                });

                defaultDiscountUpdates.push({
                    variableName: LogikFieldsEnum.set_of_discounts_discount_input,
                    index: discountIndex,
                    value: 'Monthly Promo',
                    set: discountsSetName,
                });

                discountIndex++;
            }
        }
    }

    const layoutUrl = getDefaultLayout(configuration)?.url;
    if (layoutUrl) thunkAPI.dispatch(fetchTooltips({ layoutUrl }));
    await dispatch(postProcessConfiguration({ configuration, skipRelatedPulls: true }));
    await dispatch(setUserProfile(configuration.uuid));

    const setsToFetch = configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [];

    if (defaultDiscountUpdates.length > 0) {
        await dispatch(
            updateConfiguration({
                updates: [
                    {
                        set: discountsSetName,
                        variableName: `set.${discountsSetName}.size`,
                        value: uniqBy(defaultDiscountUpdates, (d) => d.index).map((d, i) => (i + 1).toString()),
                    },
                ],
                uuid: configuration.uuid,
                skipRelatedUpdates: true,
            }),
        );

        const updated = await dispatch(
            updateConfiguration({
                updates: defaultDiscountUpdates,
                uuid: configuration.uuid,
                skipRelatedUpdates: true,
            }),
        ).unwrap();

        if (updated.configuration) {
            setsToFetch.concat(
                updated.configuration.relatedChanges?.filter((ch) => ch.type === 'SET').map((ch) => ch.key) ?? [],
            );
        }
    }

    await Promise.all([
        await dispatch(
            fetchSets({
                uuid: configuration.uuid,
                sets: setsToFetch,
            }),
        ),
        await dispatch(fetchPricingDetails(configuration.uuid)),
    ]);

    await dispatch(removeDeprecatedDiscounts({ configuration }));
    dispatch(setUUID(configuration.uuid));

    return configuration;
});

export const addCreateInitConfigurationCases = (
    builder: ActionReducerMapBuilder<WidgetState>,
): ActionReducerMapBuilder<WidgetState> => {
    return builder
        .addCase(createInitConfiguration.pending, (state) => {
            state.loading = true;
        })
        .addCase(createInitConfiguration.fulfilled, (state, action) => {
            state.productId = action.payload.fields.find((f) => f.variableName === LogikFieldsEnum.product)
                ?.value as string;

            state.loading = false;
        })
        .addCase(createInitConfiguration.rejected, (state) => {
            state.loading = false;
        });
};
