import { FormInstance } from 'antd';
import { DeviceType } from '../../shared/hooks/screen';
import { LogikForm } from '../../shared/logik/components/LogikForm';
import { LogikFormItem } from '../../shared/logik/components/LogikFormItem';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import { CustomerFormState } from './CustomerPage';
import { LogikInput } from '../../shared/logik/components/LogikInput';
import { ContactLookupFieldEnum, CustomerLookupInput } from './CustomerLookupInput';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { useCallback, useMemo, useState } from 'react';

type CustomInfoFormProps = {
    form: FormInstance<CustomerFormState>;
    device: DeviceType;
    updateShippingFields: (fields: Partial<CustomerFormState>) => void;
    validateShippingFields: () => void;
};
export const CustomInfoForm = ({
    form,
    device,
    updateShippingFields,
    validateShippingFields,
}: CustomInfoFormProps): JSX.Element => {
    const userProfile = useFieldValue<string>(LogikFieldsEnum.userProfile);

    const [canReopenPopoverMap, setCanReopenPopoverMap] = useState<{ [key in ContactLookupFieldEnum]?: boolean }>({});

    const updateCanReopenPopover = useCallback(
        (field: ContactLookupFieldEnum, value: boolean) => {
            setCanReopenPopoverMap(() => ({ [field]: value }));
        },
        [setCanReopenPopoverMap],
    );

    const canReopenPopoverName = useMemo(() => {
        return canReopenPopoverMap[ContactLookupFieldEnum.name] ?? false;
    }, [canReopenPopoverMap]);

    const canReopenPopoverEmail = useMemo(() => {
        return canReopenPopoverMap[ContactLookupFieldEnum.email] ?? false;
    }, [canReopenPopoverMap]);

    return (
        <LogikForm
            name='customer-form'
            form={form}
            layout={device === 'mobile' || device == 'tablet' ? 'vertical' : 'horizontal'}
            labelCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 8 }}
            wrapperCol={{ span: device === 'mobile' || device === 'tablet' ? 24 : 16 }}
            labelAlign='left'
            requiredMark={true}
            autoComplete='on'>
            <div className='ant-form-header'>Customer Info</div>
            <LogikFormItem
                logikName={LogikFieldsEnum.cusName}
                label='Customer Name'
                rules={[
                    {
                        required: true,
                        message: 'Customer name is required',
                    },
                ]}
                withDebounce={true}>
                {userProfile === 'Customer' ? (
                    <LogikInput placeholder='Enter your name' autoFocus autoComplete='name' />
                ) : (
                    <CustomerLookupInput
                        canReopenPopover={canReopenPopoverName}
                        updateCanReopenPopover={updateCanReopenPopover}
                        customerForm={form}
                        field={ContactLookupFieldEnum.name}
                        validateShippingFields={validateShippingFields}
                        updateShippingFields={updateShippingFields}
                    />
                )}
            </LogikFormItem>

            <LogikFormItem
                logikName={LogikFieldsEnum.cusEmail}
                label='Email address'
                rules={[
                    {
                        required: true,
                        message: 'Email is required',
                    },
                    {
                        type: 'email',
                        message: 'Email is has incorrect format',
                    },
                ]}
                withDebounce={true}>
                {userProfile === 'Customer' ? (
                    <LogikInput placeholder='Enter your email' autoComplete='email' />
                ) : (
                    <CustomerLookupInput
                        canReopenPopover={canReopenPopoverEmail}
                        updateCanReopenPopover={updateCanReopenPopover}
                        customerForm={form}
                        field={ContactLookupFieldEnum.email}
                        validateShippingFields={validateShippingFields}
                        updateShippingFields={updateShippingFields}
                    />
                )}
            </LogikFormItem>

            <LogikFormItem logikName={LogikFieldsEnum.cusCompany} label='Company Name' withDebounce={true}>
                <LogikInput autoComplete='organization' placeholder='Optional Company Name' />
            </LogikFormItem>
        </LogikForm>
    );
};
