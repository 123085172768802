import logger from '../../app/logger';
import { Field, getFieldValueFromArray } from '../../shared/logik/models/field.model';
import { notification } from 'antd';
import { Environment } from '../../app/environment';
import { useCallback, useMemo, useState } from 'react';
import { IFrameEventParams } from '../iframe-proxy/IFrameProxy';
import { ConfigurationMessage } from '../../shared/logik/models/message.model';
import { SaveQuoteDialog } from '../wizard/SaveQuoteDialog';
import { LogikFieldsEnum } from '../../shared/logik/constants/fields.constant';
import {
    fieldsLogic,
    objSnaphotType,
    ProductConfiguration,
    propertyForLogicType,
    responseMessageLogicType,
    returnObjectApi,
    returnObjectThreekit,
} from '@ulrichlifestyle/configurator';
import { useNavigateExtended } from '../../shared/hooks/navigate.hooks';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { setProductId, setSaving, setSiteId, setThreekitId, setUUID } from '../widget/Widget.slice';
import { updateConfiguration } from '../widget/thunks/basic/updateConfiguration';
import { fetchConfiguration } from '../widget/thunks/basic/fetchConfiguration';
import { FlexibleModalHandle } from '../../shared/components/modal/flexible-modal';
import { useEffect } from 'react';
import { FlexibleModal } from '../../shared/components/modal/FlexibleModal';
import { NotificationPlacement } from 'antd/es/notification/interface';
import styles from './Configurator.module.scss';
import React from 'react';
import { PricingModal } from './PricingModal';
import { setUserProfile } from '../widget/thunks/init/setUserProfile';
import { saveOrder } from '../checkout/thunk/saveOrder';
import { updateTotalPrice } from './thunks/updateTotalPrice';
import { ROUTE_CUSTOMER_INFO, ROUTE_PRODUCTS } from '../wizard/wizard.router';
import { useFieldValue } from '../../shared/logik/hooks/field.hooks';
import { EventBus } from '../../app/eventbus';
import { inIframe } from '../../shared/utils/document.utils';
import { SaveOrderAndRedirectEventParams, SaveOrderAndRedirectEvent } from '../orders/OrderCreatedPage';
import { saveConfiguration } from '../widget/thunks/actions/saveConfiguration';
import { updateTaxRate } from '../tax/thunks/updateTaxRateThunk';
import { saveAndUploadSnapshots } from './thunks/snapshots';
import { useOpenPricingWindowCallback } from '../../shared/hooks/pricing-window.hooks';

export type ConfiguratorSetup = {
    fields: Field[];
    messages: ConfigurationMessage[];
    uuid: string;
    threeKitId?: string;
    tooltips: Record<string, string>;
};

export const SaveConfigurationEvent = 'SaveConfigurationEvent';
export const ErrorEvent = 'ErrorEvent';

export type SaveConfigurationEventParams = IFrameEventParams;

export type ErrorEventParams = IFrameEventParams & {
    error: Error;
};
type ConfiguratorProps = ConfiguratorSetup;
const notificationDuration = 5;
const notificationPlacement: NotificationPlacement = 'bottomLeft';
const errorMessage = 'An unexpected error occurred';
const notificationKey = 'configurator-notification';

export const ConfiguratorImpl = ({ uuid, fields, messages, threeKitId, tooltips }: ConfiguratorProps): JSX.Element => {
    const navigate = useNavigateExtended();
    const [modalHandle, setModalHandle] = useState<FlexibleModalHandle<typeof SaveQuoteDialog>>();
    const [pricingModalHandle, setPricingModalHandle] = useState<FlexibleModalHandle<typeof PricingModal>>();
    const dispatch = useAppDispatch();
    const isCheckedOut = useFieldValue<boolean>(LogikFieldsEnum.checkedOut);
    const actionType = useAppSelector((state) => state.widget.init?.actionType);
    const sourceMasterConfigurationId = useAppSelector((state) => state.widget.init?.sourceMasterConfigId);
    const openPricingInExternalWindow = useOpenPricingWindowCallback();

    const masterId = useAppSelector((state) => state.widget.masterConfigurationId);

    const saveButtonTitle = useMemo(
        () => (actionType === 'NewCR' || actionType === 'EditCR' ? 'Save' : isCheckedOut ? 'Save Order' : 'Save Quote'),
        [actionType, isCheckedOut],
    );

    const updateSiteId = useCallback(
        (fields: fieldsLogic) => {
            dispatch(setSiteId(fields.find((f) => f.variableName === LogikFieldsEnum.siteId)?.value));
        },
        [dispatch],
    );

    const finishConfiguration = useCallback(
        async (
            uuid: string,
            conf: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ): Promise<returnObjectApi> => {
            updateSiteId(conf);

            const shortId = await saveThreeKitConfig();
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();

            dispatch(setThreekitId(shortId));
            await dispatch(updateConfiguration({ uuid, updates: snapshots, skipRelatedUpdates: true }));
            await dispatch(fetchConfiguration(uuid));
            await dispatch(updateTotalPrice());

            navigate('../' + ROUTE_CUSTOMER_INFO, { replace: true });

            return {
                uuid: uuid,
                fields: conf,
                message: [],
                threekitId: shortId,
            };
        },
        [dispatch, navigate, updateSiteId],
    );

    const saveQuote = useCallback(
        async (
            uuid: string,
            conf: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ): Promise<returnObjectApi> => {
            let resolve: (value: returnObjectApi) => void;
            const promise = new Promise<returnObjectApi>((r) => (resolve = r));
            const threeKitId = await saveThreeKitConfig();

            modalHandle?.open({
                uuid,
                threeKitId,
                productId: getFieldValueFromArray<string>(conf as Field[], LogikFieldsEnum.product),
                onPreSave: async () => {
                    updateSiteId(conf);
                    const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
                    await dispatch(fetchConfiguration(uuid));
                    await dispatch(updateConfiguration({ uuid, updates: snapshots, skipRelatedUpdates: true }));
                },
                onSave: (data) => {
                    modalHandle?.close();
                    resolve({
                        uuid: data.newConfiguration.uuid,
                        fields: data.newConfiguration.fields as fieldsLogic,
                        message: data.newConfiguration.messages,
                        threekitId: threeKitId,
                    });
                },
                onCancel: () => {
                    resolve({
                        uuid,
                        fields: conf,
                        message: [],
                    });
                },
            });

            return promise;
        },
        [dispatch, modalHandle, updateSiteId],
    );

    const showError = useCallback(
        (error: Error) => {
            dispatch(setSaving(false));
            EventBus.dispatch<ErrorEventParams>(ErrorEvent, {
                error,
            });

            const message = Environment.isDevelopment ? `${error.message}.` : '';

            notification.error({
                message: errorMessage,
                description: `${message}Please try again later`,
                duration: notificationDuration,
                placement: notificationPlacement,
                key: notificationKey,
            });

            logger.error(error);
        },
        [dispatch],
    );

    const goBack = useCallback(() => {
        dispatch(setUUID(undefined));
        dispatch(setThreekitId(undefined));
        dispatch(setProductId(undefined));

        navigate('../' + ROUTE_PRODUCTS, { replace: true });
    }, [dispatch, navigate]);

    const showDetails = useCallback(async (): Promise<void> => {
        await dispatch(
            updateTotalPrice({
                forceFetchBom: true,
            }),
        );
        pricingModalHandle?.open();
    }, [dispatch, pricingModalHandle]);

    const onZipChange = useCallback(
        async (_: string, fields: propertyForLogicType[]) => {
            const siteId = fields.find((f) => f.variableName === LogikFieldsEnum.siteId);
            if (siteId) {
                dispatch(setSiteId(siteId.value));

                await dispatch(
                    updateConfiguration({
                        uuid,
                        updates: [
                            {
                                variableName: LogikFieldsEnum.shipping_address,
                                value: '',
                            },
                            {
                                variableName: LogikFieldsEnum.shipping_city,
                                value: '',
                            },
                            {
                                variableName: LogikFieldsEnum.shipping_state,
                                value: '',
                            },
                            {
                                variableName: LogikFieldsEnum.shipping_country,
                                value: '',
                            },
                        ],
                    }),
                ).unwrap();

                await Promise.all([
                    dispatch(setUserProfile(uuid)),
                    dispatch(updateTaxRate({})).catch(() => {
                        notification.error({
                            message: 'Error getting tax rate for your location',
                            duration: 5,
                            placement: 'bottomLeft',
                        });
                    }),
                ]);
                await dispatch(updateTotalPrice({ forceFetchBom: true, uuid }));
                const config = await dispatch(fetchConfiguration(uuid)).unwrap();

                return {
                    fields: config.fields as propertyForLogicType[],
                    messages: config.messages as responseMessageLogicType[],
                };
            } else {
                return {
                    fields: fields,
                };
            }
        },
        [dispatch, uuid],
    );

    const updateOrder = useCallback(
        async (
            uuid: string,
            fields: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ): Promise<returnObjectApi> => {
            dispatch(setSaving(true));
            const shortId = await saveThreeKitConfig();
            dispatch(setThreekitId(shortId));
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();
            await dispatch(updateConfiguration({ uuid, updates: snapshots, skipRelatedUpdates: true }));

            const conf = await dispatch(saveOrder()).unwrap();
            const siteId = fields.find((f) => f.variableName === LogikFieldsEnum.siteId)?.value;

            if (siteId && masterId) {
                EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                    masterId,
                    siteId: siteId.toString(),
                    logikId: conf.uuid,
                    newOrder: true,
                });

                if (!inIframe()) {
                    setTimeout(() => {
                        window.location.href = `${Environment.omsOrigin}/${siteId}/orders/configurator-transition/${siteId}/${conf.uuid}?newOrder=true`;
                    }, 0);
                }
            }
            return {
                uuid: conf.uuid,
                fields: conf.fields as fieldsLogic,
                message: conf.messages,
                threekitId: shortId,
            };
        },
        [dispatch, masterId],
    );

    const saveConfig = useCallback(
        async (
            uuid: string,
            fields: fieldsLogic,
            saveThreeKitConfig: () => Promise<returnObjectThreekit>,
            getSnapshots: () => Promise<objSnaphotType[]>,
        ): Promise<returnObjectApi> => {
            dispatch(setSaving(true));
            const shortId = await saveThreeKitConfig();
            dispatch(setThreekitId(shortId));
            updateSiteId(fields);
            const snapshots = await dispatch(saveAndUploadSnapshots({ uuid, getSnapshots })).unwrap();

            const conf = await dispatch(
                saveConfiguration({
                    updates: snapshots,
                }),
            ).unwrap();

            const siteId = fields.find((f) => f.variableName === LogikFieldsEnum.siteId)?.value;

            if (siteId && sourceMasterConfigurationId) {
                EventBus.dispatch<SaveOrderAndRedirectEventParams>(SaveOrderAndRedirectEvent, {
                    masterId: sourceMasterConfigurationId,
                    siteId: siteId.toString(),
                    logikIdCR: conf.configuration.uuid,
                    masterIdCR: conf.masterId,
                    newOrder: actionType === 'NewCR',
                    newCR: actionType === 'NewCR',
                    editCR: actionType === 'EditCR',
                });

                if (!inIframe()) {
                    setTimeout(() => {
                        window.location.href = `${Environment.omsOrigin}/${siteId}/orders/configurator-transition/${siteId}/${conf.configuration.uuid}?newOrder=true`;
                    }, 0);
                }
            }

            return {
                uuid: uuid,
                fields: fields as fieldsLogic,
                message: [],
                threekitId: await saveThreeKitConfig(),
            };
        },
        [actionType, dispatch, sourceMasterConfigurationId, updateSiteId],
    );

    const saveFunc = useMemo(() => {
        if (actionType === 'NewCR' || actionType === 'EditCR') {
            return saveConfig;
        } else if (isCheckedOut) {
            return updateOrder;
        } else {
            return saveQuote;
        }
    }, [actionType, isCheckedOut, saveConfig, saveQuote, updateOrder]);

    useEffect(() => {
        if (!modalHandle) {
            const modal = FlexibleModal.createModal(SaveQuoteDialog, {
                showCloseButton: true,
                destroyOnClose: true,
            });
            setModalHandle(modal);
        }

        if (!pricingModalHandle) {
            const modal = FlexibleModal.createModal(PricingModal, {
                showCloseButton: true,
                destroyOnClose: true,
                className: styles['pricing-modal'],
            });
            setPricingModalHandle(modal);
        }
    }, [modalHandle, pricingModalHandle]);

    return uuid ? (
        <div className={styles['configurator-container']}>
            <ProductConfiguration
                initializationThreekit={{
                    threekitUrl: Environment.threekitUrl,
                    authToken: Environment.threekitApiToken,
                    assetId: Environment.threekitAssetId,
                    apiToken: `Bearer ${Environment.logikApiToken}`,
                    apiUrl: Environment.logikApiUrl,
                    orgId: Environment.threekitOrgId,
                    eighthWallApiKey: Environment.appEighthWallApiKey,
                }}
                logicConfig={{
                    fields: fields as fieldsLogic,
                    message: messages,
                    uuid,
                    hints: tooltips,
                }}
                reconfigurationConfig={threeKitId ? { threeKitId } : undefined}
                onFinishConfig={finishConfiguration}
                saveButtonTitle={saveButtonTitle}
                saveButtonCallback={saveFunc}
                onError={showError}
                goBackProductList={goBack}
                onGetInfoConfiguration={showDetails}
                onChangeZipCode={onZipChange}
                openPricingInExternalWindow={openPricingInExternalWindow}
            />
        </div>
    ) : (
        <></>
    );
};

export const Configurator = React.memo(ConfiguratorImpl, (prev, next) => prev.uuid === next.uuid);
